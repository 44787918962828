<template>
  <app-layout>
    <v-container grid-list-md>
      <v-card fill-height style="min-height: 800px">
        <v-tabs fill-height slider-color="accent">
          <v-tab
            :key="'tab-' + tab.tabLabel"
            :to="{ name: tab.routeTo.name }"
            ripple
            v-for="tab in tabs"
            >{{ tab.tabLabel }}
          </v-tab>
        </v-tabs>
        <router-view />
      </v-card>
    </v-container>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'

export default {
  name: 'Admin',
  components: { AppLayout },
  data() {
    return {
      tabs: [
        {
          tabLabel: 'Benutzerrollen',
          routeTo: { name: 'Admin.Roles' },
        },
        { tabLabel: 'Firmen', routeTo: { name: 'Admin.Companies' } },
        { tabLabel: 'Zinsen', routeTo: { name: 'Admin.InterestRates' } },
        {
          tabLabel: 'Zinszeiträume',
          routeTo: { name: 'Admin.DefaultInterestPeriods' },
        },
        {
          tabLabel: 'Einstellungen',
          routeTo: { name: 'Admin.Settings' },
        },
        {
          tabLabel: 'Dokumentlog',
          routeTo: { name: 'Admin.AuditTrail' },
        },
        { tabLabel: 'Backup', routeTo: { name: 'Admin.Backup' } },
        { tabLabel: 'Logs', routeTo: { name: 'Admin.Logs' } },
      ],
    }
  },
}
</script>

<style></style>
